import React from "react";
import DefaultPageContainer from "@templates/DefaultPageContainer";
import { Text, Container, Button } from "@atoms";

const FallbackPage = ({ error }) => {
  return (
    <DefaultPageContainer>
      <div className="flex flex-grow flex-col items-center justify-center text-center">
        <Container className="flex flex-col items-center justify-center">
          <Text variant="h1" className="mb-4">
            Something went wrong.
          </Text>
          <Text variant="lg" className="mb-4">
            There was an error. Please try reloading.
          </Text>
          {process.env.GATSBY_IN_DEV && (
            <pre className="mx-auto mb-4 rounded-md border border-gray p-2 text-xs">
              <code>{error}</code>
            </pre>
          )}
          <Button
            onClick={() => {
              if (typeof window !== "undefined") window.location.reload();
            }}
          >
            Reload the page
          </Button>
        </Container>
      </div>
    </DefaultPageContainer>
  );
};

export default FallbackPage;
